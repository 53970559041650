
import { languageFullNames } from "./Lida"

// export async function IpFind() {
//   return await fetch('https://api.country.is/').then(response => response.json())
// }

export default async function Geolocalize() {
  const valid_currencies = ['USD', 'EUR', 'GBP']
  const response = await (await fetch('https://api.country.is/')).json()
  let data = await (await fetch('https://restcountries.com/v3.1/alpha/'+response.country)).json()
  data = data[0]
  const currencies = Object.keys(data.currencies)
  const languages = Object.keys(data.languages)
  const country_name = data.name.common

  let ideal_currency = 'USD'
  let ideal_language = 'en'
  let ideal_location = 'United States'

  if (currencies.length > 0) {
    if (valid_currencies.includes(currencies[0])) {
      ideal_currency = currencies[0]
    }
  }

  if (languages.length > 0) {
    let exp_language = languages[0].slice(0, 2)
    if (Object.keys(languageFullNames).includes(exp_language)) {
      ideal_language = exp_language
    }
  }

  if ((country_name !== null) && (country_name !== undefined)) {
    ideal_location = country_name
  }

  return {
    currency: ideal_currency,
    language: ideal_language,
    location: ideal_location
  }
}

export async function GeolocalizeOLD() {
  const valid_currencies = ['USD', 'EUR', 'GBP']
  return await fetch('https://api.country.is/').then(response => response.json()).then(r => {
    fetch('https://restcountries.com/v3.1/alpha/'+r.country).then(resp => resp.json()).then(d => {
      const data = d[0]

      const currencies = Object.keys(data.currencies)
      const languages = Object.keys(data.languages)
      const country_name = data.name.common

      let ideal_currency = 'USD'
      let ideal_language = 'en'
      let ideal_location = 'United States'

      if (currencies.length > 0) {
        if (valid_currencies.includes(currencies[0])) {
          ideal_currency = currencies[0]
        }
      }

      if (languages.length > 0) {
        let exp_language = languages[0].slice(0, 2)
        if (Object.keys(languageFullNames).includes(exp_language)) {
          ideal_language = exp_language
        }
      }

      if ((country_name !== null) && (country_name !== undefined)) {
        ideal_location = country_name
      }

      return {
        currency: ideal_currency,
        language: ideal_language,
        location: ideal_location
      }
    })
  })
}