
import { useEffect, useRef, useState } from 'react'

import './Iris.css'
import { Helmet } from 'react-helmet';

function Logo(props:any) {
  return <svg width="126" height="21" viewBox="0 0 126 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.45879 0.5C8.24311 0.5 8.91538 0.518674 9.4756 0.556022C10.0545 0.593371 10.5867 0.649393 11.0722 0.72409C11.5578 0.798786 12.062 0.901493 12.5848 1.03221L12.3608 2.43277C11.8005 2.35808 11.287 2.30205 10.8201 2.2647C10.372 2.22736 9.87709 2.19935 9.33554 2.18067C8.81267 2.14332 8.18708 2.12465 7.45879 2.12465C6.07691 2.12465 4.98447 2.39542 4.18148 2.93698C3.37849 3.45985 2.80893 4.3282 2.4728 5.54202C2.13666 6.75584 1.9686 8.4085 1.9686 10.5C1.9686 12.5915 2.13666 14.2442 2.4728 15.458C2.80893 16.6718 3.37849 17.5495 4.18148 18.091C4.98447 18.6139 6.07691 18.8754 7.45879 18.8754C8.61659 18.8754 9.59698 18.8473 10.4 18.7913C11.203 18.7353 11.9873 18.6513 12.7529 18.5392L12.977 19.9118C12.1927 20.1172 11.3804 20.2666 10.54 20.3599C9.71836 20.4533 8.69129 20.5 7.45879 20.5C5.6474 20.5 4.19082 20.1825 3.08905 19.5476C2.00595 18.894 1.22163 17.8389 0.736104 16.3824C0.250576 14.9258 0.0078125 12.965 0.0078125 10.5C0.0078125 8.03501 0.250576 6.07423 0.736104 4.61765C1.22163 3.16106 2.00595 2.11531 3.08905 1.48039C4.19082 0.826797 5.6474 0.5 7.45879 0.5Z" fill="white"/>
    <path d="M26.7972 0.836133V16.6064C26.7972 17.2414 26.9746 17.7362 27.3295 18.091C27.6843 18.4272 28.1885 18.5952 28.8421 18.5952H36.0409L36.097 20.0238C34.8645 20.0985 33.6133 20.1452 32.3435 20.1639C31.0736 20.1825 29.8038 20.1919 28.5339 20.1919C27.3201 20.1919 26.4238 19.8744 25.8449 19.2395C25.266 18.5859 24.9765 17.7829 24.9765 16.8305V0.836133H26.7972Z" fill="white"/>
    <path d="M75.2553 0.836133V20.1639H73.4346V0.836133H75.2553Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M54.8566 0.836133C55.0247 0.836133 55.1741 0.882819 55.3048 0.976189C55.4542 1.06956 55.5475 1.20028 55.5849 1.36835L62.0555 20.1639H60.1507L58.0125 13.7493H49.5998L47.4616 20.1639H45.5569L52.0275 1.36835C52.0835 1.20028 52.1769 1.06956 52.3076 0.976189C52.4383 0.882819 52.5877 0.836133 52.7558 0.836133H54.8566ZM54.4644 3.07703C54.5578 3.33847 54.6418 3.59991 54.7165 3.86134L57.4803 12.1527H50.132L52.8958 3.86134C52.9892 3.59991 53.0732 3.33847 53.1479 3.07703C53.2413 2.79692 53.3253 2.53548 53.4 2.29272H54.2123C54.287 2.53548 54.3711 2.79692 54.4644 3.07703Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M95.3667 0.584033C96.8046 0.584033 97.9624 0.7521 98.8401 1.08823C99.7178 1.4057 100.353 1.96592 100.745 2.76891C101.156 3.55322 101.361 4.67367 101.361 6.13025C101.361 7.21335 101.24 8.10971 100.997 8.81933C100.754 9.51027 100.371 10.0425 99.8485 10.416C99.3256 10.7894 98.644 11.0415 97.8037 11.1723V11.2843C98.1211 11.3777 98.4293 11.5458 98.7281 11.7885C99.0268 12.0313 99.2976 12.3394 99.5404 12.7129C99.7831 13.0864 99.9699 13.5252 100.101 14.0294L102.061 20.1639H100.101L98.2519 14.0854C97.9904 13.2824 97.6263 12.7129 97.1594 12.3767C96.7112 12.0406 96.1417 11.8725 95.4507 11.8725H92.5096C91.8039 11.8569 91.197 11.8412 90.6888 11.8255V20.1639H88.8681V0.836133C89.559 0.761438 90.2033 0.705416 90.8009 0.668067C91.3984 0.630719 92.052 0.612045 92.7617 0.612045C93.4899 0.59337 94.3583 0.584033 95.3667 0.584033ZM97.7757 9.91176C97.1781 10.1359 96.3564 10.2572 95.3107 10.2759H90.6888V2.2385C90.9181 2.2373 91.1702 2.23669 91.4451 2.23669C92.5282 2.21802 93.8167 2.20868 95.3107 2.20868C96.3564 2.20868 97.1781 2.32073 97.7757 2.54482C98.3919 2.76891 98.8308 3.1704 99.0922 3.7493C99.3536 4.3282 99.4844 5.14986 99.4844 6.21429C99.4844 7.26004 99.3536 8.0817 99.0922 8.67927C98.8308 9.25817 98.3919 9.669 97.7757 9.91176Z" fill="white"/>
    <path d="M117.727 0.808123H120.5C121.434 0.808123 122.358 0.826797 123.273 0.864146C124.207 0.88282 125.112 0.920168 125.99 0.976189L125.906 2.40476H118.035C117.419 2.40476 116.943 2.57283 116.606 2.90896C116.289 3.2451 116.13 3.73996 116.13 4.39356V9.15546H124.786V10.6401H116.13V16.6064C116.13 17.26 116.289 17.7642 116.606 18.119C116.943 18.4552 117.419 18.6232 118.035 18.6232H125.906L125.99 20.0238C125.112 20.0798 124.207 20.1172 123.273 20.1359C122.358 20.1545 121.434 20.1639 120.5 20.1639C119.566 20.1825 118.642 20.1919 117.727 20.1919C116.7 20.1919 115.869 19.9024 115.234 19.3235C114.618 18.726 114.309 17.951 114.309 16.9986V4.0014C114.309 3.03035 114.618 2.25537 115.234 1.67647C115.869 1.09757 116.7 0.808123 117.727 0.808123Z" fill="white"/>
  </svg>
  
}

function getRandomQuote(): string {
  const quotes: string[] = [
    "Carpe diem.",
    "Stay positive.",
    "Just do it.",
    "Think big.",
    "Seize the day.",
    "Be kind.",
    "Dream big.",
    "Stay humble.",
    "Enjoy life.",
    "Keep moving.",
    "Embrace change.",
    "Be happy.",
    "Stay strong.",
    "Work hard.",
    "Believe in yourself.",
    "Stay curious.",
    "Live fully.",
    "Act now.",
    "Be fearless.",
    "Never settle.",
    "Find joy.",
    "Take chances.",
    "Stay focused.",
    "Create your future.",
    "Make it happen.",
    "Be grateful."
  ];
  const randomIndex = Math.floor(Math.random() * quotes.length);
  return quotes[randomIndex];
}

let recognition:any = null
if ('webkitSpeechRecognition' in window) {
  recognition = new webkitSpeechRecognition()
  recognition.continuous = true;
  recognition.lang = 'it-IT'
}


function useSpeechRecognition() {
  const [text, setText] = useState<string>('')
  const [isListening, setIsListening] = useState<boolean>(false)

  useEffect(() => {
    if (!recognition) return

    recognition.onresult = (event: SpeechRecognitionEvent) => {
      setText(event.results[event.results.length-1][0].transcript)
    }

    recognition.onend = () => {
      recognition.start();
    };

  }, [])

  const startListening = () => {
    setText('')
    setIsListening(true)
    recognition.start()
  }

  
  const stopListening = () => {
    setIsListening(false)
    recognition.stop()
  }

  return {
    text,
    setText,
    isListening,
    startListening,
    stopListening,
    hasRecognitionSupport: !!recognition
  }

}

let audio:any;

export default function Iris() {
  
  const videoSource = 'https://cdn.dribbble.com/userupload/12266285/file/original-3b009b0c0dccfa3891b49158bb3dbd81.mp4'
  // const videoSource = '/original.mp4'

  const videoRef = useRef<any>(null)

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.play()
    }
  }, []);

  const {text, setText, isListening, startListening, stopListening, hasRecognitionSupport} = useSpeechRecognition()

  const [messages, setMessages] = useState<any>(['Titan has pioneered in the data labeling.', 'As an immortal being, I wander through endless time, ...'])
  const [bound, setBound] = useState(3)
  const [talking, setTalking] = useState(false)
  const [thinking, setThinking] = useState(false)

  function getLastTwoElements<T>(arr: T[], _bound:number=bound): T[] {
    if (arr.length < _bound) { return arr }
    return arr.slice(-_bound);
  }

  const [hue, setHue] = useState<number>(0)
  const [energy, setEnergy] = useState<number>(1)
  const feelingsTable = {
    'normal' : 0,
    'fear' : 25,
    'anxiety' : 35,
    'emotion' : 65,
    'disgust' : 95,
    'knowledge' : 185,
    'sadness' : 255,
  }



  useEffect(() => {
    const my_interval = setInterval(() => {
      setMessages([...messages, getRandomQuote()])
    }, 1000)
    return () => clearInterval(my_interval)
  }, [messages, setMessages])

  function opacity(n:number) {
    return (n-bound+1)/2 + 1
  }


  useEffect(() => {
    startListening()
  }, [])

  const API_KEY = 'sk_6T8QU4OYNdJZ2TzcJsGnvoJuAIsNUPfMlzRLz_Pcumk'

  async function gpt5(t: string): Promise<string> {
    const headers = {
      'X-API-KEY': API_KEY,
      'Content-Type': 'application/json'
    };

    const data = {
      user_inputs: {
        Node_Name_6: { value: t }
      }
    };

    const response = await fetch(
      'https://api.forgeai.com/v1/apps/64f58d40d6afd948e09b39d5/view/run',
      {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(data)
      }
    );

    const responseData = await response.json();
    return responseData.user_outputs.Node_Name_7.value;
  }

  

  // useEffect(() => {
  //   if (audio) {
  //     audio.autoplay = true;
  //   }
  // }, [audio])

  
  const [ignore, setIgnore] = useState(false)
  const [mute, setMute] = useState(false)
  
  const voices = {
    'jessica' : 'cgSgspJ2msm6clMCkdW9',
    'will' : 'bIHbv24MWmeRgasZH58o'
  }

  const [voice, setVoice] = useState(voices['jessica'])

  function segmentation(alignment:any) {

  }

  const XI_API = [
    "sk_b5cfa5985b1a6ae5fbd401adb6d4fa268fd15dd28d9eae88",
    "sk_942e6de3d589c704dd2008033b82ed910d0a852b7e837e81"
  ]

  async function getSpeech(t:string) {
    const url = `https://api.elevenlabs.io/v1/text-to-speech/${voice}`;
    const headers = {
      "Accept": "audio/mpeg",
      "Content-Type": "application/json",
      "xi-api-key": XI_API[1]
    };
    const data = {
      text: t,
      model_id: "eleven_multilingual_v2",
      voice_settings: {
        stability: 0.5,
        similarity_boost: 0.5
      }
    };

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(data)
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const audioContext = new window.AudioContext();
      const audioBlob = await response.blob();
      // console.log('Audio received:', audioBlob);
      // setThinking(false)
      // const audioUrl = URL.createObjectURL(audioBlob);
      // audio = new Audio(audioUrl);
      // audio.autoplay = true;
      // audio.addEventListener('loadedmetadata', () => {
      //   setTalking(true)
      //   setIgnore(true)
      //   stopListening()
      //   setTimeout(() => { 
      //     setTalking(false)
      //     setText('')
      //   }, (audio.duration+1)*1000)
      //   audio.play();
      // });
      console.log('Audio received:', audioBlob);
      setThinking(false);
      const arrayBuffer = await audioBlob.arrayBuffer();
      const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
      const source = audioContext.createBufferSource();
      source.buffer = audioBuffer;
      source.connect(audioContext.destination);
      setTalking(true);
      setIgnore(true);
      stopListening();
      setTimeout(() => {
        setTalking(false);
        setText('');
      }, (audioBuffer.duration + 1) * 1000);
      source.start();
      source.onended = () => {
        console.log("Audio playback finished.");
      };
    } catch (error) {
      console.error('Error:', error);
    }
  }

  useEffect(() => {
    if (!ignore) {
      if (text.length > 0) {
        if (!talking) {
          // alert(text)
          setThinking(true)
          gpt5(text).then(r => {
            // alert(r)
            // console.log(r)
            getSpeech(r)
            setText('')
          })
        } else {
          setText('')
        }
      }
    } else {
      setIgnore(!ignore)
    }
  }, [text])

  useEffect(() => {
    console.log(talking)
  }, [talking])

  // useEffect(() => {

  // })

  // const thinkingVideoSource = 'https://cdn.dribbble.com/userupload/11716057/file/original-097c7d06c2a05fa35e37137761f4a13b.mp4'
  const thinkingVideoSource = 'https://cdn.dribbble.com/userupload/12084241/file/original-b507b736387559b36766da23936f214d.mp4'

  return <div className='iris-container'>
    <div onClick={() => setThinking(!thinking)} className={"video-container " + (talking ? "video-talking" : "")} style={{filter: `hue-rotate(${hue}deg) saturate(${energy})`}}>
      <video ref={videoRef} src={videoSource} playsInline autoPlay muted loop style={{opacity: (thinking ? 1 : 1), transition: '300ms'}}></video>
      {/* <video src={thinkingVideoSource} autoPlay muted loop style={{position: 'absolute', opacity: (thinking ? 1 : 0), filter: 'hue-rotate(170deg) saturate(2) brightness(1.2)', transform: 'scale(0.6)', transition: '300ms'}}></video> */}
    </div>
    <Helmet>
      <meta charSet="utf-8" />
      <title>CLAIRE</title>
      <link rel="icon" type="image/png" href="claire.jpg" sizes="256x256" />
      <link rel="apple-touch-icon" href="claire.jpg"/>
      {/* <link rel="canonical" href="http://mysite.com/example" /> */}
    </Helmet>
    <Logo />
    {/* <h1 style={{color: 'white'}}>{text}</h1> */}
    <div className="swift">
      <div className="iris-content">
        <div className="container">
          <div className="v-scroll-container"  style={{transform: `translateY(calc(-${18+5}px*${messages.length-bound}))`, transition: '300ms'}}>
            {
              messages.map((i:any, n:number) => <h2 style={{opacity: (n+1-messages.length)/2+1.25, transform: `scale(${(n+1-messages.length)/2+1.5})`, transition: '300ms'}}>{i}</h2>)
            }
          </div>
        </div>
      </div>
    </div>
  </div>
}